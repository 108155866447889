
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

textarea {
  width: 100%;
  height: 69vh;
  padding: 0;
  margin: 0;
  text-align: left;
  display: flex;
}


 
h2 {
  margin-top: 30px;
}

ul {
  list-style-type: none;
  padding: 0;
  display: inline-block; 
}

li {
  text-align: left;
  padding: 5px;
}
